import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"company"}},[(!_vm.getting)?_c('div',[_c('div',{staticClass:"text-h3 font-weight-bold mb-12",staticStyle:{"font-size":"30px"}},[_vm._v(" "+_vm._s(_vm.$t("company.title"))+" ")]),(_vm.hasCompany)?_c(VContainer,{attrs:{"fluid":""}},[_vm._v(" "+_vm._s(_vm.$t("company.partof"))+" ")]):_c(VContainer,[_c('div',{staticClass:"text-h6 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t("company.notpartof"))+" ")]),_c('div',{staticClass:"d-flex align-center mb-4"},[_c('span',[_vm._v(_vm._s(_vm.$t("company.create")))]),_c(VSpacer),_c(VBtn,{attrs:{"link":"","to":"/company/create","elevation":"0","color":"primary"}},[_c('span',[_vm._v(_vm._s(_vm.$t("company.createbtn")))])])],1),_c('div',[_vm._v(_vm._s(_vm.$t("company.invitations")))]),_c(VCard,{staticClass:"mt-4",attrs:{"elevation":"0"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t("inv.title")))]),(_vm.invitations.length > 0)?_c(VCardText,[_c(VList,_vm._l((_vm.invitations),function(invitation,i){return _c(VListItem,{key:i},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold"},[_vm._v(_vm._s(invitation.company.Name))]),_c(VListItemSubtitle,[_vm._v(_vm._s(invitation.isManager ? _vm.$t("inv.asmanager") : _vm.$t("inv.asemployee")))])],1),_c(VListItemAction,[_c(VTooltip,{attrs:{"top":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.accept(invitation)}}},on),[_c(VIcon,[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("inv.accept")))])])],1)],1)}),1)],1):_c(VCardText,[_c(VList,[_c('div',{staticClass:"px-4"},[_vm._v(_vm._s(_vm.$t("inv.nonesent")))])])],1)],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }